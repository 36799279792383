"use client";

import useDashboard from "@/hooks/dashboard/useDashboard";
import useDashboardActions from "@/hooks/dashboard/useDashboardActions";
import { useParamsClient } from "@/hooks/params";
import type { Client, DashboardState } from "@/redux/slices/dashboard/types";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

const LayoutDashboard: React.FC<React.PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const { lang, guildId, botId, isGuildId, isBotId } = useParamsClient();
    const { getGuild, getBotMutualguilds } = useDashboard();
    const { user, bots, guilds, loadBots, loadGuild, addGuild, loadGuildConfig, changeGuildState } = useDashboardActions();

    useEffect(() => {
        const fechBotsMutualGuilds = async () => {
            const resolvedAllPromises = await Promise.all(
                bots
                    .filter(item => item.id !== "custom")
                    .map(async item => {
                        const id = item.username.split(" ")[1];
                        return getBotMutualguilds(id);
                    })
            );

            const newBots: Client[] = [];
            for (const item of bots) {
                if (item.id === "custom" || item.guilds.length > 0) {
                    newBots.push(item);
                    continue;
                }

                const id = +item.username.split(" ")[1];

                newBots.push({
                    ...item,
                    guilds: resolvedAllPromises[id - 1]
                });
            }

            loadBots(newBots);
        };

        fechBotsMutualGuilds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fechGuild = async () => {
            changeGuildState("loading");

            if (guildId && botId === "custom") {
                changeGuildState("empty");

                return;
            }

            const guildCache = guilds.find(item => item.guild?.id === guildId && item.botId === botId);
            if (guildCache) {
                loadGuild(guildCache.guild as unknown as DashboardState["guild"]);
                if (guildCache.guild?.config) {
                    loadGuildConfig({
                        option: "reset",
                        data: guildCache.guild?.config
                    });
                }

                changeGuildState("loaded");
            } else {
                const guildFetch = await getGuild(guildId, botId);
                const guildFinded = user?.guilds?.find(item => item.id === guildId) as unknown as DashboardState["guild"];

                if (!guildFetch && !guildFinded) {
                    changeGuildState("empty");
                    router.push(`/${lang}/dashboard`);

                    return;
                }

                if (!guildFetch ?? !guildFinded) {
                    changeGuildState("empty");
                    return;
                }

                if (guildFetch) {
                    loadGuildConfig({
                        option: "reset",
                        data: guildFetch.config
                    });
                }

                loadGuild(guildFetch ?? guildFinded);
                addGuild({
                    botId,
                    guild: guildFetch ?? guildFinded
                });
                changeGuildState("loaded");
            }
        };

        if (user?.guilds?.length && isGuildId && isBotId) fechGuild();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guildId, botId, user?.guilds]);

    return <main>{children}</main>;
};

export default LayoutDashboard;
