import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { UiState } from "./types";

export const uiInitialState: UiState = {
    goUp: false,
    loader: {
        isOpen: false,
        type: "unknown"
    },
    modal: {
        isOpen: false,
        messages: []
    },
    language: {} as UiState["language"]
};

const uiSlice = createSlice({
    name: "ui",
    initialState: uiInitialState,
    reducers: {
        toggleGoUp(state, action: PayloadAction<UiState["goUp"]>) {
            state.goUp = action.payload;
        },
        showLoader(state, action: PayloadAction<UiState["loader"]>) {
            state.loader = action.payload;
        },
        hideLoader(state) {
            state.loader = uiInitialState.loader;
        },
        loadLanguage(state, action: PayloadAction<UiState["language"]>) {
            state.language = action.payload;
        }
    }
});

export const uiReducer = uiSlice.reducer;
export const {
    toggleGoUp: toggleGoUpActionCreator,
    showLoader: showLoaderActionCreator,
    hideLoader: hideLoaderActionCreator,
    loadLanguage: loadLanguageActionCreator
} = uiSlice.actions;
